<template>
  <div class="report_detail_container">
    <div
      class="header"
      v-if="query.name === '放射报告' || query.name === '病理报告'"
    ></div>
    <van-notice-bar
      class="top"
      color="#1989fa"
      background="#ecf9ff"
      left-icon="orders-o"
      v-if="query.name === '检查报告'"
      >检查单明细</van-notice-bar
    >
    <div class="checked_content" v-if="query.name === '检查报告'">
      <div>
        检查科室：
        <span>{{ checkedReportDetail.deptName }}</span>
      </div>
      <div>
        报告医生：
        <span>{{ checkedReportDetail.doctorName }}</span>
      </div>
      <div>
        报告时间：
        <span>{{ checkedReportDetail.reportTime }}</span>
      </div>
      <div>
        检查部位：
        <span>{{ checkedReportDetail.checkPart }}</span>
      </div>
      <div>
        诊断结果：
        <span>{{ checkSituation === "" ? "无" : checkSituation }}</span>
      </div>
      <div>
        影像所见：
        <span>{{ diagReuslt === "" ? "无" : diagReuslt }}</span>
      </div>
    </div>
    <van-notice-bar
      class="top"
      color="#1989fa"
      background="#ecf9ff"
      left-icon="orders-o"
      v-if="query.name === '检验报告'"
      >化验单明细</van-notice-bar
    >
    <div class="examine_content" v-if="query.name === '检验报告'">
      <div class="header">
        <div>
          检验名称：
          <span>{{ inspectedReportDetail.inspectName }}</span>
        </div>
        <div>
          检验科室：
          <span>{{ inspectedReportDetail.deptName }}</span>
        </div>
        <div>
          报告医生：
          <span>{{ inspectedReportDetail.reportDoctor }}</span>
        </div>
        <div>
          报告时间：
          <span>{{ inspectedReportDetail.reportTime }}</span>
        </div>
      </div>
      <div class="table">
        <van-row class="thead">
          <van-col span="5">项目名称</van-col>
          <van-col span="5">结果</van-col>
          <van-col span="4">提示</van-col>
          <van-col span="6">参考值</van-col>
          <van-col span="4">单位</van-col>
        </van-row>
        <van-row
          class="tr"
          v-for="(item, index) in inspectedReportDetail.Item"
          :key="index"
        >
          <div
            :class="
              item.abnormal === '0' ||
              item.abnormal === '-1' ||
              item.abnormal == '    '
                ? ''
                : 'abnormal'
            "
          >
            <van-col span="5">{{ item.itemName }}</van-col>
            <van-col span="5">{{ item.result }}</van-col>
            <van-col span="4" v-if="item.abnormal === '0'">正常</van-col>
            <van-col span="4" v-else-if="item.abnormal === '-1'">-</van-col>
            <van-col span="4" v-else-if="item.abnormal === '1'">↑</van-col>
            <van-col span="4" v-else-if="item.abnormal === '2'">↓</van-col>
            <van-col span="4" v-else-if="item.abnormal === '5'">异常</van-col>
            <van-col span="4" v-else-if="item.abnormal === '6'">危急</van-col>
            <van-col span="4" v-else-if="item.abnormal === '56'">极高</van-col>
            <van-col span="4" v-else-if="item.abnormal === '256'">极低</van-col>
            <van-col span="4" v-else-if="item.abnormal === '9'">阳性</van-col>
            <van-col span="4" v-else-if="item.abnormal === '10'"
              >弱阳性</van-col
            >
            <van-col span="4" v-else-if="item.abnormal === '11'">阴性</van-col>
            <van-col span="6">{{
              item.refRange === "" ? "-" : item.refRange
            }}</van-col>
            <van-col span="4">{{ item.unit === "" ? "-" : item.unit }}</van-col>
          </div>
        </van-row>
      </div>
    </div>
    <div v-if="name == '放射报告' || name == '病理报告'">
      <div class="checked_content">
        <div>
          报告医生：
          <span>{{ query.report_doc }}</span>
        </div>

        <div>
          报告时间：
          <span>{{ query.reportDate }}</span>
        </div>
        <div>
          检查部位：
          <span>{{ query.checkarea }}</span>
        </div>
        <div>
          描述：
          <span>{{ query.descript1 }}</span>
        </div>
        <div>
          诊断结果：
          <span>{{ query.result1 }}</span>
        </div>
        <div class="line"></div>
      </div>
    </div>

    <van-notice-bar
      class="bottom"
      color="#1989fa"
      background="#ecf9ff"
      :scrollable="false"
      wrapable
      >此报告仅对送检标本负责！结果供送检医生参考，如有疑问，请到服务台咨询</van-notice-bar
    >
    <van-button type="info" @click="back" class="bottom_back">返回</van-button>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data() {
    return {
      name: "",
      patCardNo: "",
      patCardType: "",
      query: {},
      checkedReportDetail: {},
      checkSituation: "",
      diagReuslt: "",
      inspectedReportDetail: {},
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      radiateList: []
    };
  },
  created() {
    // this.getMembers()
    this.name = this.$route.query.name;
    this.query = this.$route.query;
    this.date = this.formatTime(-90);
    this.end = this.formatTime(0);
    if (this.query.name === "检查报告") {
      this.getCheckResultDetail();
    } else if (this.query.name === "检验报告") {
      this.getExamineReportDetail();
    }
    let str = window.location.href;
    let code = str.split("?");
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    //获取所有建档人
    async getMembers() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(
            res.data[i].name + "(" + res.data[i].pat_card_no + ")"
          );
        }
        this.patName = this.patNameList[this.patNameIndex];
        if (this.name == "放射报告") {
          this.getRadiateReportDetail();
        } else if (this.name == "病理报告") {
          this.getPathologyReportDetail();
        }
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    // 检查报告详情
    async getCheckResultDetail() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/checkResultDetail",
        {
          examNo: this.query.examNo,
          patCardNo: this.$route.query.patCardNo,
          patCardType: "1",
          patIdCard: this.$route.query.patIdCard
          // patCardType: "1",
          // patCardNo: "22111872",
          // examNo: "103443"
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.checkedReportDetail = res.data;
        this.checkSituation = res.data.checkSituation.replace(/\\n/g, "\n");
        this.diagReuslt = res.data.diagReuslt.replace(/\\n/g, "\n");
      } else {
        this.inspectedReportDetail = "";
        this.$toast.fail(res.msg);
      }
    },
    // 检验报告详情
    async getExamineReportDetail() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/examineReportDetail",
        {
          examNo: this.query.examNo,
          patCardNo: this.$route.query.patCardNo,
          patCardType: this.$route.query.patCardType,
          patIdCard: this.$route.query.patIdCard
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        // console.log(res.data.Item);
      let  cleanedData = res.data.Item
        // let cleanedData = res.data.Item.map(item => {
        //   return {
        //     result: item.result.replace(/\\n/g, ""),
        //     refRange: item.refRange.replace(/\\n/g, ""),
        //     itemName: item.itemName.replace(/\\n/g, ""),
        //     unit: item.unit.replace(/\\n/g, ""),
        //     abnormal: item.abnormal.replace(/\\n/g, "")
        //   };
        // });

        // 打印清理后的数据
        console.log(cleanedData);
        if (!Array.isArray(res.data.Item)) {
          let item = [];
          item.push(res.data.Item);
          res.data.Item = item;
          this.inspectedReportDetail = res.data;
          console.log(12);
        } else {
          this.inspectedReportDetail = res.data;
          this.inspectedReportDetail.Item = cleanedData;
          console.log(122);
        }
      } else {
        this.inspectedReportDetail = "";
        this.$toast.fail(res.msg);
      }
    },
    // 放射报告详情
    async getRadiateReportDetail() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/risResultDetail",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          beginDate: this.date,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        for (let i = 0; i < res.data.examine.length; i++) {
          res.data.examine[i].result1 = res.data.examine[i].result.replace(
            /\\n/g,
            "\n"
          );
          res.data.examine[i].descript1 = res.data.examine[i].descript.replace(
            /\\n/g,
            "\n"
          );
        }
        this.radiateList = res.data.examine;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 病理报告详情
    async getPathologyReportDetail() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/pisResultDetail",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          beginDate: this.date,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        for (let i = 0; i < res.data.examine.length; i++) {
          res.data.examine[i].result1 = res.data.examine[i].result.replace(
            /\\n/g,
            "\n"
          );
          res.data.examine[i].descript1 = res.data.examine[i].descript.replace(
            /\\n/g,
            "\n"
          );
        }
        this.radiateList = res.data.examine;
        console.log(111, res.data.examine);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm(value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.radiateList = [];
      if (this.name === "放射报告") {
        this.getRadiateReportDetail();
      } else if (this.name === "病理报告") {
        this.getPathologyReportDetail();
      }
    },
    dateConfirm(value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.radiateList = [];
      if (this.name === "放射报告") {
        this.getRadiateReportDetail();
      } else if (this.name === "病理报告") {
        this.getPathologyReportDetail();
      }
    },
    dateCancel() {
      this.datePickerShow = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime(num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat(dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    }
  }
};
</script>

<style scope>
.line {
  width: 100%;
  height: 5px;
  background-color: black;
}

.report_detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.report_detail_container .header .picker .van-cell {
  padding: 30px;
}

.report_detail_container .header .picker .van-cell::after {
  border: 0;
}

.report_detail_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.report_detail_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.report_detail_container .header .picker .van-icon {
  font-size: 40px;
}

.report_detail_container .header .picker .van-picker-column {
  font-size: 40px;
}

.report_detail_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.report_detail_container .header .picker .van-picker__cancel,
.report_detail_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.report_detail_container .header .van-notice-bar__content {
  font-size: 32px;
}

.report_detail_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.report_detail_container {
  padding-bottom: 210px;
}

.report_detail_container .top {
  position: sticky;
  top: 0;
  left: 0;
  font-size: 36px;
  height: 100px;
}

.report_detail_container .van-notice-bar__left-icon {
  font-size: 36px;
}

.report_detail_container .checked_content {
  padding: 10px 30px 40px 10px;
  font-size: 36px;
  line-height: 100px;
}

.report_detail_container .checked_content span {
  color: #1989fa;
}

.report_detail_container .examine_content .header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
  font-size: 36px;
  line-height: 80px;
}

.report_detail_container .examine_content .header span {
  color: #1989fa;
}

.report_detail_container .examine_content .table {
  font-size: 34px;
  text-align: center;
}

.report_detail_container .examine_content .table .thead {
  padding: 20px 10px;
  background-color: #f5f5f5;
}

.report_detail_container .examine_content .table .tr {
  padding: 20px 10px;
  border-bottom: 1px solid #f5f5f5;
}

.report_detail_container .examine_content .table .tr .abnormal {
  color: red;
}

.report_detail_container .bottom {
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 100%;
  height: 150px;
  font-size: 34px;
  padding: 20px 30px;
  line-height: 60px;
}

.report_detail_container .bottom_back {
  position: fixed;
  left: 0;
  bottom: 200px;
  width: 100%;
  height: 80px;
  font-size: 34px;
  padding: 20px 30px;
  line-height: 100px;
}
</style>
